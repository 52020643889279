#contact {
    .container.contact {
        margin-top: 3%;
        @include verticalCenter;
        a {
            margin-left: 2%;
            margin-right: 2%;
            &:hover {
                color: $primary;
            }
        }
    }
}