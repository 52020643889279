// ---------------Carousel----------------------

.carousel {
  .item,
  .active {
    width: 100%;
    height: 50vh;
    @include verticalCenter;
  }
  .carousel-inner {
    height: 100%;
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
  }
  p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    color: hsla(0, 0%, 100%, 0.8);
    margin-top: 0;
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 8vmin;
    color: #fff;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 10px;
    display: inline-block;
    position: relative;
  }
}

// ---------------Buttons git & in----------------------

$thick: 3px;
$pad: 0.1em;
$extra: calc(#{$pad} * 1.2);

.btns {
  @include verticalCenter;

  a {
    color: white;
    padding: $pad $extra;
    border: $thick solid transparent;
    position: relative;
    margin-left: 1%;
    margin-right: 1%;
    cursor: pointer;

    .text {
      transform: translate3d(0, $pad, 0);
      transition: transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
    }

    &:after {
      position: absolute;
      content: "";
      bottom: -$thick;
      left: $extra;
      right: $extra;
      height: $thick;
      background: $white;
      transition: transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s,
        right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s,
        left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s;
      transform-origin: left;
    }
  }

  .line {
    position: absolute;
    background: $white;

    &.-right,
    &.-left {
      width: $thick;
      bottom: -$thick;
      top: -$thick;
      transform: scale3d(1, 0, 1);
    }

    &.-top,
    &.-bottom {
      height: $thick;
      left: -$thick;
      right: -$thick;
      transform: scale3d(0, 1, 1);
    }

    &.-right {
      right: -$thick;
      transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      transform-origin: top;
    }

    &.-top {
      top: -$thick;
      transition: transform 0.08s linear 0.43s;
      transform-origin: left;
    }

    &.-left {
      left: -$thick;
      transition: transform 0.08s linear 0.51s;
      transform-origin: bottom;
    }

    &.-bottom {
      bottom: -$thick;
      transition: transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      transform-origin: right;
    }
  }

  a:hover,
  a:active {
    .text {
      transform: translate3d(0, 0, 0);
      transition: transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
    }

    &:after {
      transform: scale3d(0, 1, 1);
      right: -$thick;
      left: -$thick;
      transform-origin: right;
      transition: transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s,
        right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s;
    }

    .line {
      transform: scale3d(1, 1, 1);

      &.-right {
        transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        transform-origin: bottom;
      }

      &.-top {
        transition: transform 0.08s linear 0.4s;
        transform-origin: right;
      }

      &.-left {
        transition: transform 0.08s linear 0.48s;
        transform-origin: top;
      }

      &.-bottom {
        transition: transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        transform-origin: left;
      }
    }
  }
}
