// ---------------Coté gauche----------------------

header {
  padding: 2em 0;
  @media (max-width: 768px) {
    padding: 2em 0 0 !important;
  }
}

header .name-logo {
  float: left;
}

.logo {
  color: $white !important;
  font-size: 25px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 7px;
  display: block;
  text-align: center;
  position: relative;
  padding-left: 62px;
  user-select: none;
}

.logo-img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// ---------------Coté droite----------------------

.rightSide {
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: 15px;
  #langSelect {
    @include verticalCenter;
    .btn-primary {
      background-color: unset !important;
      border: unset !important;
    }
    .btn:focus,
    .btn:active {
      outline: none !important;
      box-shadow: none;
    }
    .dropdown-menu {
      background-color: unset !important;
      border: unset !important;
      .dropdown-item {
        color: $white;
      }
    }
    a:hover {
        background-color: #42424277;
    }
  }
  .nav-toggle {
    cursor: pointer;
    text-decoration: none;
    margin-top: -3px !important;

    &.active i {
      &::before,
      &::after {
        background: $black;
      }
    }

    &.dark {
      &.active i {
        &::before,
        &::after {
          background: $black;
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      border-bottom: none !important;
    }

    i {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 2px;
      color: $black;
      font: bold 14px/0.4 Helvetica;
      text-transform: uppercase;
      text-indent: -55px;
      background: $white;
      transition: all 0.2s ease-out;

      .menu-show & {
        background: $white;
        color: $white;
      }

      &::before,
      &::after {
        content: "";
        width: 40px;
        height: 2px;
        background: $white;
        position: absolute;
        left: 0;

        .menu-show & {
          background: $white;
        }
      }
    }

    &.dark {
      i {
        position: relative;
        color: $white;
        background: $white;
        transition: all 0.2s ease-out;

        &::before,
        &::after {
          background: $white;
        }
      }
    }
  }

  .nav-toggle i::before {
    top: -7px;
  }

  .nav-toggle i::after {
    bottom: -7px;
  }

  .nav-toggle:hover i::before {
    top: -10px;
  }

  .nav-toggle:hover i::after {
    bottom: -10px;
  }

  .nav-toggle.active i {
    background: transparent;
  }

  .nav-toggle.active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .nav-toggle.active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }

  .nav-toggle {
    float: right;
    z-index: 1003;
    position: relative;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 9px;
  }
}

// ---------------Menu nav----------------------

#main-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.99);
  box-shadow: 0 0 8px 8px rgba($black, 0.99) inset;
  z-index: 1002;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);

  .col-table {
    display: table;
    width: 100%;
    height: 100%;

    .col-table-cell {
      display: table-cell;
      vertical-align: middle;
      position: relative;
    }
  }

  .menu-show & {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  .logo {
    color: $white;
  }

  ul {
    padding: 0;
    margin: 0;
    display: block;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      font-weight: 400;
      font-size: 40px;

      a {
        display: block;
        color: rgba(255, 255, 255, 1);
        padding: 5px 0;

        span {
          font-size: 40px;
          color: rgba(255, 255, 255, 1);
          position: relative;
          padding: 0 10px;

          small {
            position: absolute;
            bottom: 15px;
            left: -30px;
            font-size: 16px;
            color: darken($white, 60%);
            border-bottom: 1px solid darken($white, 60%);
            @media (max-width: 768px) {
              visibility: hidden !important;
            }
          }
        }
      }

      &.active,
      &:active {
        a {
          span {
            color: $primary;
          }
        }
      }

      &:hover {
        a {
          transform: translate(3px, 3px);
        }
      }
    }
  }
}
