.title {
    @include verticalCenter;
    h3 {
        text-align: center;
        user-select : none;
        &:hover {
            border-bottom: 3px solid $white;
        }
        @media (max-width: 992px) {
            position: unset !important;
            transform: unset !important;
            height: unset !important;
            left: unset !important;
		}
    }
}

.circKnow {
    .col {
        margin-bottom: 5%;
        .CircularProgressbar {
            margin-left: 2%;
            user-select : none;
        }
    }
    @media (max-width: 992px) {
        text-align: center;
    }
}

.CircularProgressbar {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 20%;
}