// ---------------Button scrollDown----------------------

.scrollDownButton {
    position: fixed;
    right: 10%;
    bottom: 10%;
    cursor: pointer;
    -moz-animation: scrollDownButton 2s infinite;
    -webkit-animation: scrollDownButton 2s infinite;
    animation: scrollDownButton 2s infinite;
}

@keyframes scrollDownButton {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}