.about-section {
    position: relative;
    margin-bottom: 20%;
    h2{
		font-weight: 300;
		letter-spacing: 1px;
    }
    .author-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
.wrap {
    padding: 0;
    .about-desc {
        position: relative;
        .bold-text {
            position: absolute;
            z-index: -1;
            top: 10px;
            left: -60px;
            right: 0;
            bottom: 0;
            font-size: 6em;
            font-weight: 700 !important;
            color: rgba(255, 255, 255, 0.08);
            text-transform: uppercase;
            user-select : none;
            @media (max-width: 992px) {
                width: 100%;
                word-wrap: break-word;
                font-size: 5em;
                left: 8px;
            }
        }
        p {
            color: #8c8c8c;
            a {
                word-wrap: break-word;
            }
        }
        .ftco-footer-social li {
            list-style: none;
            margin: 0 10px 10px 0;
            display: inline-block;
            cursor: pointer;
        }
    }
    a {
        cursor: pointer;
        color: $primary !important;
        &:hover {
            color: $white !important;
        }
    }
}


// ---------------Formations----------------------
.experiences-section {
    margin-bottom: 5%;
}

.education-wrapper {
    position: relative;

    h5 {
        color: $primary;
    }

    h6 {
        margin-top: 15px;
    }

    @media (max-width: 992px) {
        margin-left: 20%;
    }
}

.education-wrapper:after {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 0;
    left: -51px;
    width: 2px;
    background: #ccc;

    @media (max-width: 992px) {
        left: -23px;
    }

    @media (max-width: 575px) {
        left: null !important;
    }
}

.education-wrapper .education {
    position: relative;
    margin-bottom: 15%;
}

.education-wrapper .education:after {
    content: '';
    position: absolute;
    top: 8px;
    left: -58px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0 0 0 8px #2f88fc85;
    background: $primary;

    @media (max-width: 992px) {
        left: -30px;
    }
}